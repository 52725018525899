import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { IndicatorValue, Topic } from '@shapeable/gesda-types';
import { CellLabel, ContentEntityIntro, ContentNode, DottedLine, EntityGrid, Grid, NextPageLink, PersonBioCell, useLang, useView, VideoImage } from '@shapeable/ui';
import { patchTrendSocietyView, transformTopicExperts } from '../../utils/transforms';
import { TopicView } from '../../types';
import { sortBy } from 'lodash';
import { GptLayout } from '@shapeable/gpt';
import { TopicAnticipatoryImpactLayout } from './topic-anticipatory-impact-layout';
import { TopicAnticipationPotentialChart } from '@shapeable/gesda-ui';
import { PersonCard } from './person-card';
import { useCurrentEditionMembers } from '../../hooks/use-current-edition-members';
const cls = classNames('topic-view-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicViewLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicViewLayoutDefaultProps: Omit<TopicViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};    
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    .shp--gpt-prompt {
      &:first-child {
        border-top: none;
      }
    };
  `,
});

const ContentLabelStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text-mid')};
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const IntroStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(0)};
    font-size: 1em;
  `,
});

const VideoStyles = breakpoints({
  base: css`
    
  `,
});

const ContentStyles = breakpoints({
  base: css`
    font-weight: 300;
  `,
});

const ConnectionsStyles = breakpoints({
  base: css`
    .shp--card {
      background-color: ${theme.COLOR('light')};
    }
  `,
});

const ChartStyles = breakpoints({
  base: css`
    background: ${theme.COLOR('bright-warm')};
    padding: ${theme.UNIT(4)};
  `,
  desktop: css`
    padding: ${theme.UNIT(5)};
    margin-right: 0;
    margin-left: 0;
  `,
});

const AnticipatoryImpactLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const ResourcesStyles = breakpoints({
  base: css`
    
  `,
});

const SubTopicCardsStyles = breakpoints({
  base: css`
  `,
});

const SubTopicCardHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const GlobalExpertsStyles = breakpoints({
  base: css`
    ${Grid.cls.child('item')} {
      align-items: flex-start;
      justify-content: center;
    }
  `,
});

const GlobalExpertStyles = breakpoints({
  base: css`
    .shp--badge {
      padding: 0 ${theme.UNIT(2)};
    }
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(4)} 0;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  Section: styled.section`${SectionStyles}`,
      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,

      SubTopicCardHeader: styled.div`${SubTopicCardHeaderStyles}`,
        ContentLabel: styled(CellLabel)`${ContentLabelStyles}`,
        SubTopicCards: styled(EntityGrid)`${SubTopicCardsStyles}`,
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Video: styled(VideoImage)`${VideoStyles}`,
      Content: styled(ContentNode)`${ContentStyles}`,
      Chart: styled(TopicAnticipationPotentialChart)`${ChartStyles}`,
      AnticipatoryImpactLayout: styled(TopicAnticipatoryImpactLayout)`${AnticipatoryImpactLayoutStyles}`,

      Connections: styled(EntityGrid)`${ConnectionsStyles}`,
  
      NextLink: styled(NextPageLink)`${NextLinkStyles}`,

      Resources: styled(EntityGrid)`${ResourcesStyles}`,

      GlobalExperts: styled(EntityGrid)`${GlobalExpertsStyles}`,
       GlobalExpert: styled(PersonCard)`${GlobalExpertStyles}`,
};

export const TopicViewLayout: Shapeable.FC<TopicViewLayoutProps> = (props) => {
  const { className, children, entity } = props;
  
  const { showView } = useView<TopicView>(`topic-${entity.slug}`, 'overview');

  const { description, connectedPages = [], committeeMembers = [], video, anticipatoryImpact, 
    anticipatoryImpactImage, citations = [], feedEntries = [], subTopics = [], trend } = entity;

    const hasVideo = video && !!video.url;

    const filteredtrend = patchTrendSocietyView(trend, 'society');
    const connections = [...connectedPages, filteredtrend];
  
    const resources = sortBy([...citations, ...feedEntries], 'date');
  
    const tranformedExperts = transformTopicExperts(committeeMembers);
    const filteredByEdition = useCurrentEditionMembers(tranformedExperts);
    

    const t = useLang()

    const deriveValue: (iv: IndicatorValue) => number = 
    (iv) => {
      return (iv.numericValue - 0.41357) * 1000 / 5.8 + 18;
    };
  
    const hasAnticipatoryImpact = 
      anticipatoryImpact && anticipatoryImpact.text && 
      anticipatoryImpactImage && anticipatoryImpactImage.image && anticipatoryImpactImage.image.url;
  
  return (
    <My.Container className={cls.name(className)}>
    {
      showView('radar-ai') && 
        <My.Section>
          <My.GptLayout entity={entity} />
        </My.Section>
      }
      {
        showView('overview') && 
        <My.Section>
          <My.Intro entity={entity} />
          <My.SubTopicCardHeader>
            <My.ContentLabel>{t('Associated Sub-Fields:')}</My.ContentLabel>
            <My.SubTopicCards 
              items={subTopics} 
              spacing={4} 
              minColumns={2} 
              tabletAutoColumns={4} 
            />
          </My.SubTopicCardHeader>

          {
            hasVideo && (
              <My.Video entity={video} />
            )
          }
          <My.Content entity={description} />
          <My.Chart entity={entity} deriveValue={deriveValue} upperValue={60} />
          {
            hasAnticipatoryImpact && (
              <My.AnticipatoryImpactLayout entity={entity} />
            )
          }
        </My.Section>
      }
      {
        showView('connections') && 
        <My.Section>
          <My.Connections items={connections} spacing={4} />
        </My.Section>
      }
      {
        showView('resources') && 
        <My.Section>
          <My.Resources items={resources} spacing={4} />
        </My.Section>
      }
      {
        showView('contributors') && 
        <My.Section>
          <My.GlobalExperts 
            spacing={4} 
            tabletAutoColumns={3} 
            desktopAutoColumns={4} 
            items={filteredByEdition} />
        </My.Section>
      }
      <DottedLine height={2} />
      <My.NextLink />
    </My.Container>
  )
};

TopicViewLayout.defaultProps = TopicViewLayoutDefaultProps;
TopicViewLayout.cls = cls;